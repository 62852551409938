import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import EditItemQuantityButton from "../../Molecules/EditItemQuantityButton";
import DeleteItemButton from "../DeleteItemButton";
import { convertIntoSelectedCurrency } from "../SlideCartDrawer/constants";

const EcomCartCard = ({ brandsData, item, selectedCurrency, exchangeRate }) => {
  const selectedVariantStock = parseInt(
    item?.merchandise?.product?.variants?.find(
      (variant) => variant?.id === item?.merchandise?.id
    )?.stock
  );
  const availableInStock = item?.availability === "availabile";
  const size =
    item?.variant?.option_values?.find(
      (option) => option?.option_display_name?.toLowerCase() === "size"
    )?.label || item?.merchandise?.selectedOptions[0]?.value;
  const brandName =
    brandsData?.find((brand) => brand?.entityId === item?.brand_id)?.name ||
    item?.merchandise?.product?.brand;
  return (
    <div>
      <div className="flex gap-x-4 sm:gap-x-3 max-h-40">
        <div className="w-[110px] md:w-20 flex justify-center items-center ">
          <img
            className=" w-full h-full object-cover object-top"
            src={
              item?.merchandise?.product?.images?.[0]?.url ||
              item?.images?.[item?.images?.length - 1]?.url_thumbnail
            }
            alt={
              item?.merchandise?.product?.images?.[0]?.altText ||
              item?.images?.[item?.images?.length - 1]?.description
            }
          />
        </div>
        <div className=" flex-1 sm:mr-5 ">
          <div className="flex flex-col h-full justify-between">
            <div className="grid gap-y-0.5">
              <TypographyMontserrat
                fontWeight={"light"}
                size={"sm"}
                text={item?.merchandise?.title || item?.name}
                customStyle={" !uppercase "}
              />
              <TypographyMontserrat
                customStyle={"!text-sm"}
                fontWeight="medium"
                text={brandName}
              />
              <TypographyMontserrat
                fontWeight={"normal"}
                size={"sm"}
                text={
                  selectedCurrency +
                  " " +
                    item?.cost?.totalAmount?.amount ||
                      item?.variant?.sale_price ||
                      item?.price
                  
                }
              />

              <TypographyMontserrat
                fontWeight={"normal"}
                size={"sm"}
                text={"Size: " + size}
              />
              {selectedVariantStock <
                parseInt(item?.variant?.quantity || item?.quantity) && (
                <TypographyMontserrat
                  fontWeight={"medium"}
                  size={"sm"}
                  text={
                    selectedVariantStock === 0
                      ? "OUT OF STOCK"
                      : `AVAILABLE QUANTITY: ${selectedVariantStock}`
                  }
                  color={"red"}
                />
              )}
            </div>
            <div className="flex w-full justify-between gap-2">
              <div className="flex h-9 flex-row items-center bg-TESTIMONIAL_BG ">
                <EditItemQuantityButton
                  item={item}
                  type="minus"
                  selectedVariantStock={selectedVariantStock}
                />
                <p className="w-6 text-center">
                  <span className="w-full text-sm text-black">
                    {item?.variant?.quantity || item?.quantity}
                  </span>
                </p>
                <EditItemQuantityButton item={item} type="plus" />
              </div>
              <div className="flex justify-center items-center cursor-pointer">
                <DeleteItemButton item={item} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomCartCard;
