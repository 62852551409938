
export const CLOSE_BUTTON_CLASS =
  "bg-transparent rounded-lg inline-flex items-center ";
export const BACK_BUTTON_CLASS =
  "inline-flex items-center w-15 justify-between";
export const BACK_BUTTON_ICON_CLASS = "rotate-180 cursor-pointer w-3 h-3";
export const LOGO_IMAGE_CLASS = " h-4 sm:h-5 ";
export const SPACE_Y_2 = "space-y-2";
export const DRAWER_CONTAINER =
  "block xl:hidden fixed z-40 h-screen top-0 p-4 overflow-y-auto bg-white w-[228px]  ";
export const DRAWER_PARENT_CONTAINER =
  " fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ";
export const DASHBOARD_DRAWER_CONTAINER =
  " fixed z-40 h-screen top-0 right-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";
export const DASHBOARD_DRAWER_CONTAINER_AR =
  " fixed z-40 h-screen top-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";
export const DRAWER_INNER_CONTAINER = "py-4 overflow-y-none";
export const MARGING_3 = "mx-3";
export const DRAWER_OPEN_TRANSITION =
  " transition-opacity opacity-100 duration-500  translate-x-0  ";
export const DRAWER_CLOSE_TRANSITION =
  " transition-all delay-500 duration-500 opacity-0  -translate-x-full  ";
export const DRAWER_OUTER_CONTAINER = "h-full w-full";
export const DRAWER_HEADER_CLASS = "flex justify-between h-10 items-center";
export const DRAWER_LINK_CLASS =
  " inline-flex py-2.5 xl:px-2 text-Text_Gray  text-base font-semibold text-Montserrat w-full justify-between ";

