"use client";
import { sendGTMEvent } from "@next/third-parties/google";
import Link from "next/link";
import React from "react";

const FooterSocialMediaLink = ({
  link,
  id,
  className,
  label,
  gTagValue,
  icon,
}) => {
  return (
    <Link
      href={link}
      target="_blank"
      name={id}
      className={className}
      id={id}
      aria-label={label}
      onClick={() =>
        sendGTMEvent({
          event: "NavigationInteractions",
          menu_name: gTagValue,
          place: "footer",
        })
      }
    >
      {icon}
    </Link>
  );
};

export default FooterSocialMediaLink;
