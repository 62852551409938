import React from "react";
import { CATEGORIES_LIST } from "./constants";
import TypographyMontserrat, {
  Heading,
} from "@/components/Atoms/TypographyMontserrat";
import Link from "next/link";
import Image from "next/image";
import { RightArrowIcon } from "@/public/Theme";

const SearchBarCategoriesSection = ({ setShowSearchBar }) => {
  return (
    <>
      <Heading
        customStyle={"!text-base lg:!text-xl mb-2"}
        fontWeight="light"
        text={"Categories"}
      />
      <div className="grid grid-cols-2 min-[400px]:grid-cols-3 gap-x-12 lg:gap-x-4 gap-y-1 lg:gap-y-3 mb-0 lg:mb-5">
        {CATEGORIES_LIST?.map((category, index) => (
          <Link
            onClick={() => setShowSearchBar(false)}
            href={category?.link}
            key={index}
            className="max-w-[98px] sm:max-w-[103px] flex gap-x-3 justify-between hover:underline"
          >
            <TypographyMontserrat
              customStyle={"!text-xs lg:!text-[13px]"}
              fontWeight="normal"
              text={category?.text}
            />
            <Image
              alt="arrow"
              src={RightArrowIcon}
              width={0}
              height={0}
              className="w-[14px] h-[14px]"
            />
          </Link>
        ))}
      </div>
    </>
  );
};

export default SearchBarCategoriesSection;
