import LoadingDots from "../../Atoms/LoadingDots";
import { useRouter } from "next/navigation";
import { BlackBin } from "../../../public/Theme";
import clsx from "clsx";
import { removeItem } from "../../../lib/cart/actions";
import { useTransition } from "react";
import { sendGTMEvent } from "@next/third-parties/google";

export default function DeleteItemButton({ item }) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  return (
    <button
      aria-label="Remove cart item"
      onClick={() => {
        startTransition(async () => {
          const error = await removeItem([
            String(item?.line_id ? [item?.line_id] : [item?.id]),
          ]);
          sendGTMEvent({
            event: "CartAndCheckoutInteractions",
            interaction_type: "RemoveFromCart",
            interaction_item: {
              currency: item?.cost?.totalAmount?.currencyCode,
              value: item?.cost?.totalAmount?.amount,
              items: [
                {
                  item_id: item?.merchandise?.id,
                  item_name: item?.merchandise?.title,
                  item_brand: item?.merchandise?.product?.brand,

                  item_variant: item?.merchandise?.selectedOptions?.find(
                    (option) => option?.name === "Size"
                  )?.value,
                  price: item?.cost?.totalAmount?.amount,
                },
              ],
            },
          });
          if (error) {
            // Trigger the error boundary in the root error.js
            throw new Error(error.toString());
          }

          router.refresh();
        });
      }}
      disabled={isPending}
      className={clsx(
        "ease flex items-center justify-center transition-all duration-200",
        {
          "cursor-not-allowed px-0": isPending,
        }
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-black " />
      ) : (
        <img src={BlackBin} alt="delete-bin-icon" />
      )}
    </button>
  );
}
