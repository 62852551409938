export const BACKEND_ENDPOINTS = {
  ECOM_PRODUCTS: "/events/products?page=1",
  PRODUCT: "/events/product/",
  CALCULATED_DATE_RETURN: `${process?.env?.BACKEND_URL}box/calculated-date-return`,
  CREATE_BOX: `${process?.env?.BACKEND_URL}box/create-box`,
};
export const FRONTEND_ENDPOINTS = {
  PRODUCTS: "/products",
  TAMARA: "/tamara",
  PASSWORD_RESET_SUCCESS: "/login?toast=true",
  SERENA_PRODUCTS_LINK: "/products?category=108",
  SERENA_BY_THE_SEA_PRODUCTS_LINK: "/products?category=135",
  BASMA_PRODUCTS_LINK: "/products?category=100",
  PRE_STYLED_BOXES: "/products?category=103",
  TAMARA_PRODUCTS_LINK: "/products?category=106",
  JESSIE_PRODUCTS_LINK: "/products?category=124",
  OLD_MONEY_EDIT_LINK: "/products?category=148",
  TRENDING_PRODUCTS_LINK: "/products?sort=trending-desc",
  MONO_EDIT_PRODUCTS_LINK: "/products?category=117",
  CAPSULE_EDIT_PRODUCTS_LINK: "/products?category=118",
  MODEST_EDIT_PRODUCTS_LINK: "/products?category=120",
  THE_SUMMER_EDIT_PRODUCTS_LINK: "/products?category=136",
  HOT_LIST_LINK: "/products?category=123",
  MINI_CAPSULES_LINK: "/bundles",
  OLD_MONEY_EDIT_EXTERNAL_LINK: "https://my.wearthat.me/old-money",
  CORPORATE_EDIT_LINK: "/edits/corporate-edit",
  CREATIVE_EDIT_LINK: "/edits/creative-edit",
  EDUCATION_EDIT_LINK: "/edits/education-edit",
  ENTREPERNEURIAL_EDIT_LINK: "/edits/entrepreneurial-edit",
  MANAGEMENT_EDIT_LINK: "/edits/event-pr-management-edit",
  ALL_EDITS_LINK: "/edits/all-work-edit",
  NEW_JESSIE_LINK: "/jessie",
};
