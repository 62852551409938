import React from "react";
import {
  PRIMARY_BUTTON_CLASS,
  PRIMARY_BUTTON_DISABLED_CLASS,
} from "./constants";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";

const ProcessingOrText = ({ processing, text }) => {
  return processing ? (
    <div className="relative w-8 h-8">
      <Image
        src={LoadingWhiteSpinner}
        fill
        className="h-full w-full"
        alt="processing gif"
      />
    </div>
  ) : (
    text
  );
};

const InputFieldPrimaryButton = ({
  text,
  onClick,
  processing,
  width,
  href,
  className,
  style,
  type,
  disabled,
}) => {
  const buttonClass = disabled
    ? PRIMARY_BUTTON_DISABLED_CLASS
    : PRIMARY_BUTTON_CLASS;

  return href ? (
    <Link
      href={`${href}`}
      passHref
      className={width + PRIMARY_BUTTON_CLASS + className}
      style={style}
      onClick={onClick}
    >
      <ProcessingOrText processing={processing} text={text} />
    </Link>
  ) : (
    <button
      type={type}
      className={width + buttonClass + className}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <ProcessingOrText processing={processing} text={text} />
    </button>
  );
};

export default InputFieldPrimaryButton;
