const InstagramIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 5.5H28.5C32.0899 5.5 35 8.41015 35 12V28C35 31.5899 32.0899 34.5 28.5 34.5H12.5C8.91015 34.5 6 31.5899 6 28V12C6 8.41015 8.91015 5.5 12.5 5.5Z"
        stroke="#5C5C5C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9017 18.9915C27.0991 20.3231 26.8717 21.6831 26.2517 22.8779C25.6317 24.0728 24.6507 25.0418 23.4483 25.647C22.2458 26.2522 20.8832 26.4629 19.5541 26.249C18.2251 26.0351 16.9973 25.4076 16.0454 24.4558C15.0935 23.5039 14.4661 22.2761 14.2522 20.947C14.0383 19.618 14.249 18.2553 14.8542 17.0529C15.4594 15.8505 16.4284 14.8695 17.6233 14.2495C18.8181 13.6295 20.1781 13.402 21.5097 13.5995C22.868 13.8009 24.1254 14.4338 25.0964 15.4048C26.0673 16.3757 26.7003 17.6332 26.9017 18.9915Z"
        stroke="#5C5C5C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3025 11.2002H29.3187"
        stroke="#5C5C5C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default InstagramIcon;
