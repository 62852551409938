"use client";
import React from "react";
import Link from "next/link";
import TypographyMontserrat from "@/components/Atoms/TypographyMontserrat";
import { useSearchParams, usePathname } from "next/navigation";
import { CATEGORY_TEXT } from "@/public/Constants/EnumConstants";
import {
  COLLECTION_TEXT,
  HEADER_LINKS,
  PRODUCTS_PATHNAME,
  SORT,
} from "./constants";
import { sendGTMEvent } from "@next/third-parties/google";
import { deletePreviousCursor } from "@/lib/Products/actions";

const LinksComponent = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  let selectedTab =
    pathname === PRODUCTS_PATHNAME &&
    !searchParams?.get("brand") &&
    (searchParams?.get(CATEGORY_TEXT?.toLowerCase()) ||
      searchParams?.get(COLLECTION_TEXT?.toLowerCase()) ||
      searchParams?.get(SORT?.toLowerCase()));
  return (
    <div className="shop-header-links flex items-center gap-x-5 sm:gap-x-[30px] overflow-auto ">
      {HEADER_LINKS?.map((headerLink, index) => (
        <Link
          href={`${headerLink?.link}`}
          data-cy={headerLink?.linkId}
          key={index}
          prefetch
          scroll
          onClick={() => {
            sendGTMEvent({
              event: "NavigationInteractions",
              menu_name: headerLink?.heading,
              place: "topnavbar",
            });
            deletePreviousCursor();
          }}
          className={`${
            selectedTab === headerLink?.key ? "border-b pb-1" : ""
          } transition-all duration-100 hover:border-b border-white hover:pb-1`}
        >
          <TypographyMontserrat
            color={"White"}
            customStyle={" w-max !text-sm"}
            fontWeight={"medium"}
            text={headerLink?.heading}
          />
        </Link>
      ))}
    </div>
  );
};

export default LinksComponent;
