import { FRONTEND_ENDPOINTS } from "@/public/Constants/endpoints";

export const PRODUCTS_PATHNAME = "/products";
export const CATEGORY_TEXT = "category";
export const COLLECTION_TEXT = "collection";
export const SORT = "sort";
export const BUNDLES_PATHNAME = "/bundles";

export const HEADER_LINKS = [
  { heading: "ALL", link: "/products", key: null, linkId: "shop-nav-all-link" },
  {
    heading: "JESSIE",
    link: FRONTEND_ENDPOINTS?.NEW_JESSIE_LINK,
    key: "171",
    linkId: "shop-nav-jessie-link",
  },
  {
    heading: "WORK EDIT",
    link: FRONTEND_ENDPOINTS?.ALL_EDITS_LINK,
    key: "170",
    linkId: "shop-nav-work-edit-link",
  },
  {
    heading: "HOT LIST",
    link: FRONTEND_ENDPOINTS?.HOT_LIST_LINK,
    key: "123",
    linkId: "shop-nav-hot-list-link",
  },
  {
    heading: "OLD MONEY EDIT",
    link: FRONTEND_ENDPOINTS?.OLD_MONEY_EDIT_LINK,
    key: "148",
    linkId: "shop-nav-old-money-edit-link",
  },
  {
    heading: "SERENA BY THE SEA",
    link: FRONTEND_ENDPOINTS?.SERENA_BY_THE_SEA_PRODUCTS_LINK,
    key: "135",
    linkId: "shop-nav-serena-by-the-sea-link",
  },
];
