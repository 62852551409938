import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { convertIntoSelectedCurrency } from "../SlideCartDrawer/constants";
import { BUNDLES_DATA } from "@/public/Constants/EnumConstants";
import DeleteMultipleItemsButton from "../DeleteItemButton/DeleteMultipleItemsButton";
import { calculateCollectionPrice } from "@/lib/utils";

const BundleCartCard = ({
  collection,
  selectedCurrency,
  exchangeRate,
  collectionItems,
  price,
}) => {
  const bundleDetails = BUNDLES_DATA?.find(
    (bundle) => bundle?.key === collection?.category_id
  );

  const collectionPrice = calculateCollectionPrice(
    collection?.meta_description,
    price
  );
  return (
    <div>
      <div className="flex gap-x-4 sm:gap-x-3 max-h-40">
        <div className="w-[110px] md:w-20 flex justify-center items-center ">
          <img
            className=" w-full h-full object-cover"
            src={bundleDetails?.bundleImageMobile}
            alt={collection?.name}
          />
        </div>
        <div className=" flex-1 sm:mr-5 ">
          <div className="flex flex-col h-full justify-between">
            <div className="grid gap-y-1 ">
              <TypographyMontserrat
                fontWeight={"light"}
                size={"sm"}
                text={collection?.name}
                customStyle={" !uppercase "}
              />
              <TypographyMontserrat
                fontWeight={"normal"}
                size={"sm"}
                text={
                  selectedCurrency +
                  " " +
                  convertIntoSelectedCurrency(collectionPrice, exchangeRate)
                }
              />
            </div>
            <div className="flex w-full justify-between gap-2">
              <div />
              <div className="flex justify-center items-center cursor-pointer">
                <DeleteMultipleItemsButton
                  items={collectionItems}
                  bundleId={collection?.category_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundleCartCard;
