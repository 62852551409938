"use client";
import React, { useState } from "react";
import TypographyMontserrat from "@/components/Atoms/TypographyMontserrat";
import { BlackCross_24x24 } from "@/public/Theme";
import Image from "next/image";
import { BANNER_TEXT } from "./constants";

const PageTopBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  return (
    showBanner && (
      <div className="bg-Banner_Background py-3 px-4 flex gap-x-4 justify-between items-center">
        <div className="w-full">
          <TypographyMontserrat
            customStyle={"text-left sm:text-center text-[13px] sm:text-sm"}
            fontWeight="normal"
            text={BANNER_TEXT}
          />
        </div>
        <Image
          onClick={() => setShowBanner(false)}
          width={0}
          height={0}
          className={"cursor-pointer w-3 sm:w-4 h-3 sm:h-4"}
          src={BlackCross_24x24}
          alt="Cross Icon"
        />
      </div>
    )
  );
};

export default PageTopBanner;
