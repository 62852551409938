export const YOUR_CART_TEXT = "YOUR CART";
export const SUB_TOTAL_TEXT = "SUBTOTAL";
export const TOTAL_TEXT = "TOTAL";
export const ESTIMATED_TOTAL_TEXT = "ESTIMATED TOTAL";
export const SHIPPING_COST_NOTE =
  "Shipping payment will be added at the checkout.";
export const CONTINUE_SHOPPING_TEXT = "Continue Shopping";
export const CHECKOUT_TEXT = "CHECKOUT";
export const NO_ITEMS = "No Items in Cart";

export const convertIntoSelectedCurrency = (amount, exchangeRate) => {
  const finalAmount =
    Math?.round(parseFloat(amount) * parseFloat(exchangeRate) * 100) / 100;

  return Math?.round(finalAmount);
};
