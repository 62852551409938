"use client";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import {
  ARROW_CLASS,
  ARROW_ROTATE,
} from "@/components/Atoms/NewAccordion/constants";
import { changeCurrency } from "@/lib/Currency/action";
import { BlackChevronDown } from "@/public/Theme";
import { CircleFlag } from "react-circle-flags";
const DropDown = dynamic(() => import("./DropDown"));

const CurrencySelectorDropdown = ({
  selectedCountry,
  selectedCurrency,
  currenciesWithRate,
}) => {
  const [value, setValue] = useState({
    open: false,
    selectedCurrency: selectedCurrency,
    selectedCountry: selectedCountry,
  });
  // Function to handle keydown events for keyboard accessibility
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      setValue((prev) => ({ ...prev, open: !prev?.open }));
    } else if (event.key === "Escape") {
      setValue((prev) => ({ ...prev, open: false }));
    }
  };

  useEffect(() => {
    const setCountry = async () => {
      if (!selectedCurrency || !selectedCountry) {
        let result = {
          country_name: "United Arab Emirates",
          country_code: "AE",
        };
        try {
          const response = await fetch(
            `${process.env.BACKEND_URL}api/v1/getLocation/?key=${process.env.IP2LOCATION_API_KEY}`
          );
          if (response.ok) {
            result = await response.json();
          }
        } catch (e) {
          console.log(e);
        }
        let { country_name, country_code } = result;
        const geoLocation = { country: country_name, flag: country_code };
        const filterData = currenciesWithRate?.find(
          (country) => country?.iso_alpha_2 === country_code
        );
        const currencyCode = filterData?.code;
        changeCurrency(
          filterData?.country,
          filterData?.iso_alpha_2,
          filterData?.code,
          filterData?.exchangeRate
        );
        setValue({
          open: false,
          selectedCurrency: currencyCode,
          selectedCountry: geoLocation,
        });
      }
    };
    setCountry();
  }, []);

  return (
    <div className="relative w-max">
      <button
        className="border border-solid border-white cursor-pointer flex gap-x-2 items-center text-xs lg:text-sm   font-medium  h-8 lg:h-10 min-w-fit"
        onClick={() => setValue((prev) => ({ ...prev, open: !prev?.open }))}
        onKeyDown={handleKeyDown}
        aria-expanded={value?.open}
        aria-controls="currency-dropdown"
      >
        <CircleFlag
          countryCode={value?.selectedCountry?.flag?.toLowerCase()}
          alt="Flag"
          width={24}
          height={24}
        />
        <Image
          width={0}
          height={0}
          className={ARROW_CLASS + (value?.open ? ARROW_ROTATE : "")}
          src={BlackChevronDown}
          alt="Chevron"
        />
      </button>
      {value?.open && (
        <DropDown
          currenciesWithRate={currenciesWithRate}
          value={value}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default CurrencySelectorDropdown;
