import { useRouter } from "next/navigation";
import { useTransition } from "react";
import { SmallMinus, SmallPlus } from "../../../public/Theme";
import clsx from "clsx";
import { removeItem, updateItemQuantity } from "../../../lib/cart/actions";
import LoadingDots from "../../Atoms/LoadingDots";
import { toast } from "react-toastify";
import { sendGTMEvent } from "@next/third-parties/google";

export default function EditItemQuantityButton({
  item,
  type,
  selectedVariantStock,
}) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const quantity = item?.variant?.quantity || item?.quantity;
  return (
    <button
      aria-label={
        type === "plus" ? "Increase item quantity" : "Reduce item quantity"
      }
      onClick={() => {
        startTransition(async () => {
          let error = null;
          if (type === "minus" && quantity - 1 === 0) {
            error = await removeItem(
              item.line_id ? [item.line_id] : [item?.id]
            );
            sendGTMEvent({
              event: "CartAndCheckoutInteractions",
              interaction_type: "RemoveFromCart",
              interaction_item: {
                currency: item?.cost?.totalAmount?.currencyCode,
                value: item?.cost?.totalAmount?.amount,
                items: [
                  {
                    item_id: item?.merchandise?.id,
                    item_name: item?.merchandise?.title,
                    item_brand: item?.merchandise?.product?.brand,

                    item_variant: item?.merchandise?.selectedOptions?.find(
                      (option) => option?.name === "Size"
                    )?.value,
                    price: item?.cost?.totalAmount?.amount,
                  },
                ],
              },
            });
          } else {
            error = await updateItemQuantity({
              lineId: item.line_id || item?.id,
              productSlug:
                item?.custom_url?.url || item?.merchandise?.product?.handle,
              variantId: item?.variant_id || item?.merchandise?.id,
              quantity:
                type === "plus"
                  ? quantity + 1
                  : selectedVariantStock < quantity - 1
                  ? selectedVariantStock
                  : quantity - 1,
            });
          }

          if (error) {
            // Trigger the error boundary in the root error.js
            console.log("error", { error });
            toast("Insufficient stock available for this quantity", {
              type: "error",
            });
            // throw new Error(error.toString());
          }

          router.refresh();
        });
      }}
      disabled={isPending}
      className={clsx(
        "ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center  px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80",
        {
          "cursor-not-allowed": isPending,
          "ml-auto": type === "minus",
        }
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-black " />
      ) : type === "plus" ? (
        <img src={SmallPlus} className="h-auto w-auto" alt="Small plus icon" />
      ) : (
        <img
          src={SmallMinus}
          className="h-auto w-auto"
          alt="Small minus icon"
        />
      )}
    </button>
  );
}
