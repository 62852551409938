import {
  HeaderArrow,
  BasmaCollection,
  SerenaCollection,
  ShopAllCollection,
  TamaraCollection,
  SerenaBySeaCollection,
  OldMoneyEditCollection,
  AllEditCollectionsImage,
  NewJessieCollectionsImage,
} from "../../../public/Theme";
import { FRONTEND_ENDPOINTS } from "@/public/Constants/endpoints";

export const LOGO_ALT_TEXT = "Wearthat Logo";
export const LOGIN_ENDPOINT = "/login";
export const DRAWER_NAVIGATION = "drawer-navigation";
export const HEADER_LOGO_IMAGE_ID = "header_logo_img_id";
export const HEADER_BUTTON_ID = "header_button_id";
export const AR = "AR";
export const EN = "EN";
export const HEADER_LINK = [
  {
    link: "/",
    text: "Home",
    src: null,
  },
  {
    link: "/products",
    text: "Shop All",
    src: null,
  },
  {
    link: "/bundles",
    text: "Jessie's Capsules",
    src: null,
  },

  {
    link: "/#wear-that-edits",
    text: "Edits",
    src: null,
  },
  {
    link: "",
    text: "Collections",
    src: HeaderArrow,
  },
  {
    link: FRONTEND_ENDPOINTS?.HOT_LIST_LINK,
    text: "Hot List",
    src: null,
  },
  {
    link: `/shoppable-sessions`,
    text: "Shoppable Sessions",
    src: null,
  },
];

export const COLLECTIONS_LINK = [
  {
    link: FRONTEND_ENDPOINTS?.NEW_JESSIE_LINK,
    text: "Jessie Collection",
    src: NewJessieCollectionsImage,
  },
  {
    link: FRONTEND_ENDPOINTS?.ALL_EDITS_LINK,
    text: "Work Edit",
    src: AllEditCollectionsImage,
  },
  {
    link: FRONTEND_ENDPOINTS?.OLD_MONEY_EDIT_LINK,
    text: "Old Money Edit",
    src: OldMoneyEditCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.SERENA_BY_THE_SEA_PRODUCTS_LINK,
    text: "Serena By The Sea",
    src: SerenaBySeaCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.SERENA_PRODUCTS_LINK,
    text: "Serena Collection",
    src: SerenaCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.TAMARA_PRODUCTS_LINK,
    text: "Wear That x Tamara",
    src: TamaraCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.BASMA_PRODUCTS_LINK,
    text: "Wear That x Basma",
    src: BasmaCollection,
  },
  {
    link: "/products",
    text: "Shop All",
    src: ShopAllCollection,
  },
];

export function slugify(string) {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
}

export const MENU_ICON_ALT = "Menu Icon";
export const COLLECTION_TEXT = "Collections";
