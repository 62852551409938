"use client";
import React, { useEffect, useRef, useState } from "react";
import { NewInputField } from "../../Atoms/Inputs/NewInputField";
import {
  BlackCross_24x24,
  SearchIconBlack,
  SearchIconGrey,
  WTLogoEnglish,
} from "../../../public/Theme";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import SearchBarCategoriesSection from "../SearchBarCategoriesSection";
import SearchBarCollectionsSection from "../SearchBarCollectionsSection";
import SearchBarSearchedProducts from "../SearchBarSearchedProducts";
import { getProducts } from "@/lib/bigcommerce";
import { useDebounce } from "@uidotdev/usehooks";
import { useRouter, useSearchParams } from "next/navigation";
import { createUrl } from "@/lib/utils";

const HeaderSearchBar = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const ref = useRef();
  const debouncedText = useDebounce(searchValue, 300);

  const handleChange = (e) => {
    setSearchValue(e?.target?.value);
    ref?.current?.scrollIntoView();
  };

  useEffect(() => {
    setSearching(true);
    if (debouncedText !== "") {
      const searchproducts = async () => {
        const searchedProducts = await getProducts({
          query: debouncedText,
          limit: 4,
        });
        setResults(searchedProducts?.products);
        setSearching(false);
      };

      searchproducts();
    } else if (debouncedText === "") {
      setResults([]);
      setSearching(false);
    }
  }, [debouncedText]);

  useEffect(() => {
    if (showSearchBar === false) setResults([]);
  }, [showSearchBar]);

  const onSubmit = (e) => {
    e.preventDefault();

    const val = e.target;
    const search = { value: searchValue };
    const newParams = new URLSearchParams();

    if (search?.value) {
      newParams.set("q", search?.value);
    } else {
      newParams.delete("q");
    }
    setShowSearchBar(false);
    router.push(createUrl("/search", newParams));
  };

  return (
    <>
      <AnimatePresence>
        {!showSearchBar && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className=" "
          >
            <Image
              width={24}
              height={24}
              onClick={() => setShowSearchBar(true)}
              src={SearchIconBlack}
              className="transition-all ease-in-out hover:scale-110 cursor-pointer"
              alt="white search icon"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showSearchBar && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="search-bar-searched-products overflow-scroll min-[1420px]:overflow-visible fixed top-0 left-0 bg-white z-50 w-full h-full min-[1420px]:h-max pt-6 px-3 min-[930px]:px-0"
          >
            {showSearchBar && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
              >
                <div className="w-full px-1 mb-4 flex md:hidden justify-between">
                  <Image
                    alt="Wearthat Logo"
                    src={WTLogoEnglish}
                    width={0}
                    height={0}
                    className="w-[90px] h-auto mx-1"
                  />
                  <Image
                    width={20}
                    height={20}
                    alt="Cross Icon"
                    src={BlackCross_24x24}
                    onClick={() => setShowSearchBar(false)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="w-full">
                  <form
                    className=" lg:ml-20 md:mr-8 flex gap-x-[30px]"
                    onSubmit={onSubmit}
                  >
                    <div className="w-full flex border-b-[0.5px]">
                      <Image
                        alt="Search Icon"
                        src={SearchIconGrey}
                        width={0}
                        height={0}
                        className="h-auto w-auto"
                      />
                      <NewInputField
                        name={"search"}
                        type={"text"}
                        style=" !border-0 text-black w-full !text-md md:!text-xl "
                        height=" !h-[35px] "
                        placeholder={
                          "Search For Brand, Clothing or Accessories"
                        }
                        onChange={handleChange}
                      />
                    </div>
                    <Image
                      width={0}
                      height={0}
                      alt="Cross Icon"
                      src={BlackCross_24x24}
                      onClick={() => setShowSearchBar(false)}
                      className="hidden md:block cursor-pointer w-auto h-auto"
                    />
                  </form>
                  <div className="mt-3 lg:mt-7 sm:mx-3 lg:mx-20 flex flex-col min-[930px]:flex-row gap-x-[60px] gap-y-7 w-full">
                    <div className="md:max-w-[600px] min-[930px]:max-w-auto  flex flex-col gap-y-5">
                      <SearchBarCollectionsSection
                        setShowSearchBar={setShowSearchBar}
                      />
                      <SearchBarCategoriesSection
                        setShowSearchBar={setShowSearchBar}
                      />
                    </div>
                    <div ref={ref}>
                      <SearchBarSearchedProducts
                        setShowSearchBar={setShowSearchBar}
                        products={results}
                        searching={searching}
                        searchValue={searchValue}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
            <div className="hidden min-[1420px]:block absolute w-full h-screen bg-[#b6b6b6a1]" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HeaderSearchBar;
