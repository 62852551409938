const LinkedInIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.75"
      y="1.25"
      width="37.5"
      height="37.5"
      rx="3.75"
      stroke="#5C5C5C"
      strokeWidth="2.5"
    />
    <path
      d="M30.8125 23.5214V31.5625H26.1225V24.0598C26.1225 22.176 25.4452 20.8892 23.7474 20.8892C22.4519 20.8892 21.6814 21.7548 21.3416 22.5935C21.2177 22.8927 21.1858 23.3091 21.1858 23.7302V31.5613H16.4958C16.4958 31.5613 16.5584 18.8551 16.4958 17.539H21.1858V19.5261C21.1764 19.5414 21.1634 19.5578 21.1552 19.5719H21.1858V19.5261C21.8088 18.5724 22.9203 17.2094 25.4122 17.2094C28.4988 17.2106 30.8125 19.2153 30.8125 23.5214ZM11.5911 10.7812C9.98642 10.7812 8.9375 11.8288 8.9375 13.2035C8.9375 14.5502 9.95693 15.627 11.5297 15.627H11.5604C13.1957 15.627 14.2127 14.5502 14.2127 13.2035C14.1809 11.8276 13.1957 10.7812 11.5911 10.7812ZM9.21596 31.5625H13.9036V17.5402H9.21596V31.5625Z"
      fill="#5C5C5C"
    />
  </svg>
);
export default LinkedInIcon;
