const WhatsAppIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1804 22.3879C28.1181 22.358 25.7852 21.2092 25.3707 21.06C25.2014 20.9992 25.0201 20.9399 24.8273 20.9399C24.5123 20.9399 24.2477 21.0969 24.0415 21.4053C23.8085 21.7516 23.103 22.5763 22.8851 22.8226C22.8566 22.8551 22.8177 22.894 22.7945 22.894C22.7736 22.894 22.4125 22.7453 22.3032 22.6978C19.8007 21.6108 17.9012 18.9966 17.6407 18.5558C17.6035 18.4924 17.6019 18.4637 17.6016 18.4637C17.6108 18.4301 17.6949 18.3457 17.7384 18.3022C17.8654 18.1765 18.0031 18.0108 18.1363 17.8505C18.1993 17.7745 18.2625 17.6985 18.3245 17.6268C18.5178 17.4019 18.6038 17.2274 18.7035 17.0252L18.7558 16.9202C18.9993 16.4363 18.7913 16.028 18.7241 15.8962C18.669 15.7858 17.6839 13.4085 17.5792 13.1587C17.3273 12.556 16.9946 12.2754 16.5321 12.2754C16.4892 12.2754 16.5321 12.2754 16.3522 12.283C16.1331 12.2922 14.9398 12.4493 14.4122 12.7819C13.8527 13.1346 12.9062 14.259 12.9062 16.2363C12.9062 18.016 14.0356 19.6963 14.5205 20.3353C14.5325 20.3514 14.5547 20.3842 14.5868 20.4311C16.4437 23.143 18.7586 25.1528 21.1053 26.0902C23.3645 26.9927 24.4344 27.097 25.0426 27.097C25.0427 27.097 25.0427 27.097 25.0427 27.097C25.2983 27.097 25.5028 27.077 25.6833 27.0592L25.7978 27.0483C26.5782 26.9791 28.2934 26.0904 28.6835 25.0062C28.9908 24.1523 29.0719 23.2193 28.8674 22.8807C28.7273 22.6505 28.486 22.5346 28.1804 22.3879Z"
        fill="#5C5C5C"
      />
      <path
        d="M20.786 4C12.1194 4 5.06858 10.9978 5.06858 19.5993C5.06858 22.3813 5.8131 25.1045 7.22349 27.4879L4.52395 35.451C4.47367 35.5995 4.51107 35.7636 4.62089 35.8754C4.70016 35.9564 4.8077 36 4.91751 36C4.95959 36 5.00198 35.9937 5.04333 35.9805L13.3467 33.3419C15.6189 34.5559 18.187 35.1968 20.7861 35.1968C29.4519 35.1969 36.5019 28.1998 36.5019 19.5993C36.5019 10.9978 29.4519 4 20.786 4ZM20.786 31.9474C18.3404 31.9474 15.9716 31.2412 13.9355 29.9051C13.867 29.8602 13.7875 29.8371 13.7075 29.8371C13.6653 29.8371 13.6229 29.8435 13.5816 29.8566L9.42214 31.1788L10.7649 27.2174C10.8083 27.0891 10.7866 26.9477 10.7066 26.8384C9.15606 24.7198 8.33642 22.2166 8.33642 19.5993C8.33642 12.7896 13.9213 7.24945 20.7859 7.24945C27.6497 7.24945 33.2339 12.7896 33.2339 19.5993C33.234 26.4081 27.6499 31.9474 20.786 31.9474Z"
        fill="#5C5C5C"
      />
    </svg>
  );
};
export default WhatsAppIcon;
