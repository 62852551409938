import clsx from "clsx";
import { EcommCartBlack } from "../../../public/Theme";
import Image from "next/image";

export default function OpenCart({ className, quantity }) {
  return (
    <div className="relative flex h-9 w-9 items-center justify-center  text-black transition-colors ">
      <Image
        width={24}
        height={24}
        src={EcommCartBlack}
        className={clsx(
          " transition-all ease-in-out hover:scale-110",
          className
        )}
        alt="cart icon"
      />

      {quantity ? (
        <div className="absolute right-0 top-0 h-5 w-5 bg-[#F6465A]   rounded-2xl text-[13px] font-semibold text-white">
          {quantity}
        </div>
      ) : null}
    </div>
  );
}
