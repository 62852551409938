export const LOGO_ALT_TEXT = "Wearthat Logo";
export const LOGIN_ENDPOINT = "/login";
export const DRAWER_NAVIGATION = "drawer-navigation";
export const AR = "AR";
export const EN = "EN";
export const CLOSE_ICON_ALT = "close Icon";
export const BACK_ICON_ALT = "Back icon"
export const LAZY = "lazy";
export const BACK_TEXT = "Back"
export const COLLECTIONS_TEXT = "Collections"

export function slugify(string) {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
}