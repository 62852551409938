import { FRONTEND_ENDPOINTS } from "@/public/Constants/endpoints";

import { WHATSAPP_HUBSPOT, WHATSAPP_URL } from "../../Atoms/WhatsApp/constants";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  TiktokIcon,
  WhatsAppIcon,
  YoutubeIcon,
} from "@/components/Atoms/SVGs";

export const MAIN_CONTAINER_CLASS =
  "p-3 sm:px-12 xl:px-7 2xl:px-12 sm:py-12 sm:pb-7 bg-New_Secondary_Background";
export const CONTAINER_SIZE_CLASS = "max-w-7xl mx-auto ";
export const NEWSLETTER_SECTION_WRAPPER = "lg:flex justify-center";
export const NEWSLETTER_SECTION_CONTAINER = "lg:w-[596px]";
export const NEWSLETTER_HEADING_CLASS =
  "text-New_Primary_Default   text-[13px] sm:text-base my-2 ";
export const NEWSLETTER_FOOTER_CLASS =
  "text-gray-500   text-[10px] sm:text-xs	mt-2";
export const NEWS_LETTER_TERMS_CTA_CLASS = "underline decoration-gray-400";
export const COPYRIGHT_CLASS =
  "flex flex-col justify-between items-center h-12 my-4";
export const POLICY_TERM_CONTAINER =
  "flex w-60	sm:w-72	 justify-between items-center";
export const POLICY_TERM_CLASS = "text-xs sm:text-sm	font-semibold underline";
export const FOOTER_COPYRIGHT_CLASS =
  "text-xs sm:text-sm font-normal text-gray-400";
export const AR = "AR";
export const EN = "EN";
export const PRIVACY_POLICY_LINK = `${process?.env?.NEW_WEB_URL}documents/privacy-policy`;
export const TERMS_AND_CONDITIONS_LINK =
  "https://mkt-production.s3.me-south-1.amazonaws.com/terms+%26+conditions/E-commerce+Terms+and+Conditions.pdf";

export const NEWS_LETTER_HEADER_TEXT = "Sign up for updates";
export const NEWS_LETTER_FOOTER_TEXT = "By signing up you agree to our ";
export const NEWS_LETTER_TERMS_CTA = "terms";
export const NEWS_LETTER_TERMS_DOT = ".";
export const PRIVACY_POLICY = "Privacy Policy";
export const TERMS_OF_USE = "Terms of Use";
const date = new Date();
export const COPYRIGHT_TEXT = `© Copyright ${date?.getFullYear()}. All rights reserved.`;

export const FOOTER_LINKS = [
  {
    section: "Company",
    links: [
      {
        title: "About Us",
        href: `${process?.env?.NEW_WEB_URL}about-us`,
        externalLink: true,
      },
      {
        title: "Journal",
        href: `${process?.env?.NEW_WEB_URL}blogs`,
        externalLink: true,
      },
      {
        title: "Gift Card",
        href: `${process?.env?.NEW_WEB_URL}gift-card`,
        externalLink: true,
      },
    ],
  },
  {
    section: "My Account",
    links: [
      {
        title: "Take the Style Quiz",
        href: `${process?.env?.NEW_WEB_URL}survey-register`,
        externalLink: true,
      },
      {
        title: "Login",
        href: `${process?.env?.NEW_WEB_URL}login`,
        externalLink: true,
      },
      {
        title: "My Boxes",
        href: `${process?.env?.NEW_WEB_URL}dashboard/myBoxes`,
        externalLink: true,
      },
    ],
  },
  {
    section: "Shop The Look",
    links: [
      {
        title: "Jessie Collection",
        href: "products?category=124",
        externalLink: true,
      },
      {
        title: "Basma Collection",
        href: FRONTEND_ENDPOINTS?.BASMA_PRODUCTS_LINK,
      },
      {
        title: "Tamara Collection",
        href: FRONTEND_ENDPOINTS?.TAMARA_PRODUCTS_LINK,
      },
    ],
  },
  {
    section: "More From Wear That",
    links: [
      {
        title: "Refer A Friend",
        href: `${process?.env?.NEW_WEB_URL}referral`,
        externalLink: true,
      },
      {
        title: "Ambassador Program",
        href: `${process?.env?.NEW_WEB_URL}ambassador-program`,
        externalLink: true,
      },
      {
        title: "Faq",
        href: `${process?.env?.NEW_WEB_URL}faqs`,
        externalLink: true,
      },
    ],
  },
];
export const SOCIAL_MEDIA_LINKS = [
  {
    id: "whatsapp-icon",
    label: "whatsapp link",
    link: WHATSAPP_URL + WHATSAPP_HUBSPOT,
    icon: <WhatsAppIcon alt="whatsapp-icon" />,
    gTagValue: "Opened Whatsapp",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900",
  },
  {
    id: "instagram-icon",
    label: "instagram link",
    link: "https://www.instagram.com/wear.that.now/",
    icon: <InstagramIcon alt="instagram-icon" />,
    gTagValue: "Opened Instagram",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900",
  },
  {
    id: "youtube-icon",
    label: "youtube link",
    link: "https://www.youtube.com/@wearthat5213",
    icon: <YoutubeIcon alt="youtube-icon" />,
    gTagValue: "Opened Youtube",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900 ",
  },
  {
    id: "facebook-icon",
    label: "facebook link",
    link: "https://www.facebook.com/wear.that.now/",
    icon: <FacebookIcon alt="facebook-icon" />,
    gTagValue: "Opened Facebook",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900 ",
  },
  {
    id: "tiktok-icon",
    label: "tiktok link",
    link: "https://www.tiktok.com/@wearthat",
    icon: <TiktokIcon alt="tiktok-icon" />,
    gTagValue: "Opened Tiktok",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900 ",
  },
  {
    id: "pintrest-icon",
    label: "pintrest link",
    link: "https://www.pinterest.com/wearthatnow/",
    icon: <PinterestIcon alt="pintrest-icon" />,
    gTagValue: "Opened Pinterest",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900 ",
  },
  {
    id: "linkedin-icon",
    label: "linkedin link",
    link: "https://www.linkedin.com/company/wear-that/",
    icon: <LinkedInIcon alt="linkedin-icon" />,
    gTagValue: "Opened Linkedin",
    className: "w-8 sm:w-10 h-8 sm:h-10 text-gray-700 hover:text-gray-900 ",
  },
];

export function slugify(string) {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
}

export const _BUTTON_FOOTER_ID = "_button_footer_id;";
