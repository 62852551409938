"use client";
import Link from "next/link";
import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
import FlyoutLink from "../../Atoms/FlyoutLink";
import { NewMenuIcon, WTLogoEnglish } from "../../../public/Theme";
import HeaderCollectionBanner from "../HeaderCollectionBanner";

import {
  DRAWER_NAVIGATION,
  HEADER_BUTTON_ID,
  HEADER_LINK,
  COLLECTIONS_LINK,
  HEADER_LOGO_IMAGE_ID,
  LOGO_ALT_TEXT,
  MENU_ICON_ALT,
  COLLECTION_TEXT,
  slugify,
} from "./constants";
import {
  HEADER_LINK_CLASS,
  HEADER_UNORDERED_LIST_CLASS,
  LINKS_AND_BUTTONS_CONTAINER,
  LINKS_CONTAINER,
  LOGO_CLASS,
  MENU_ICON_CLASS,
  MENU_IMAGE_CLASS,
  NAVBAR_CLASS,
  LOGO_IMAGE_CLASS,
} from "./styles";
import { BUTTON } from "../../../public/Constants/EnumConstants";
import NavBarDrawer from "../NavBarDrawer";
import Image from "next/image";
import { sendGTMEvent } from "@next/third-parties/google";
import { deleteAllCursors } from "../../../lib/Products/actions";

const HeaderLink = dynamic(() => import("../../Atoms/Typography/HeaderLink"));

const NewHeaderSection = ({ homeUrl }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const getFlyoutContent = (text) => {
    if (text === COLLECTION_TEXT) return HeaderCollectionBanner;
  };

  useEffect(() => {
    deleteAllCursors();
  }, []);

  return (
    <>
      <nav className={drawerOpen ? "pl-0" + NAVBAR_CLASS : NAVBAR_CLASS}>
        <div className={LINKS_AND_BUTTONS_CONTAINER}>
          <button
            type={BUTTON}
            onClick={() => {
              setDrawerOpen(!drawerOpen);
              sendGTMEvent({
                event: "NavigationInteractions",
                menu_name: "Hamburger Icon",
                place: "drawer",
              });
            }}
            data-drawer-target={DRAWER_NAVIGATION}
            data-drawer-show={DRAWER_NAVIGATION}
            // aria-controls={DRAWER_NAVIGATION}
            className={MENU_ICON_CLASS}
          >
            <Image
              priority
              width={32}
              height={32}
              src={NewMenuIcon}
              alt={MENU_ICON_ALT}
              className={MENU_IMAGE_CLASS}
            />
          </button>
          <Link
            href={homeUrl}
            passHref
            replace={true}
            className={LOGO_CLASS}
            prefetch
            scroll
            onClick={() =>
              sendGTMEvent({
                event: "NavigationInteractions",
                menu_name: "WT Logo",
                place: "header",
              })
            }
          >
            <Image
              priority
              src={WTLogoEnglish}
              className={LOGO_IMAGE_CLASS}
              alt={LOGO_ALT_TEXT}
              id={HEADER_LOGO_IMAGE_ID}
              data-cy={HEADER_LOGO_IMAGE_ID}
              fill
              sizes="(max-width:768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </Link>
          <div className={LINKS_CONTAINER}>
            <ul className={HEADER_UNORDERED_LIST_CLASS}>
              {HEADER_LINK?.map((item, index) => (
                <li key={index} className={HEADER_LINK_CLASS}>
                  {item?.link !== "" ? (
                    <HeaderLink
                      link={item?.link}
                      text={item?.text}
                      external={item?.external}
                      headerId={slugify(HEADER_BUTTON_ID + `_${item?.text}`)}
                    ></HeaderLink>
                  ) : (
                    <FlyoutLink FlyoutContent={getFlyoutContent(item?.text)}>
                      {item?.text}
                    </FlyoutLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <NavBarDrawer
        homeUrl={homeUrl}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        headerLinks={HEADER_LINK}
        collectionLinks={COLLECTIONS_LINK}
      />
    </>
  );
};

export default NewHeaderSection;
