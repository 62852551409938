"use client";
import React, { useRef, useState, useEffect } from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { BlackCross } from "../../../public/Theme";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import OpenCart from "../../Organisms/Cart/OpenCart";
import EcomCartCard, { BundleCartCard } from "../../Molecules/EcomCartCard";
import {
  CHECKOUT_TEXT,
  CONTINUE_SHOPPING_TEXT,
  ESTIMATED_TOTAL_TEXT,
  NO_ITEMS,
  SUB_TOTAL_TEXT,
  TOTAL_TEXT,
  YOUR_CART_TEXT,
  convertIntoSelectedCurrency,
} from "./constants";
import { useRouter } from "next/navigation";
import { AnimatePresence, motion } from "framer-motion";
import {
  checkCartItemsStockAvailability,
  getDiscountedLineItems,
  spreadDiscountToItems,
} from "@/lib/utils";
import { AED_TEXT } from "../ShoppingProductCard/constants";
import useCartItems from "@/lib/useCartItems";
import Image from "next/image";
import { addDiscountToCart } from "@/lib/bigcommerce";

const SlideCartDrawer = ({
  brandsData,
  cart,
  selectedCurrency,
  exchangeRate,
  bundleIds,
}) => {
  const { cartItems } = useCartItems(cart, bundleIds);

  const breakpoint = useMediaQuery("640");
  const router = useRouter();
  const quantityRef = useRef(cart?.totalQuantity);

  const [outOfStock, setOutOfStock] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const toggleCart = () => {
    if (!openCart) {
      setOpenCart(true);
    } else {
      setOpenCart(false);
    }
  };
  useEffect(() => {
    checkCartItemsStockAvailability(cart?.lines, setOutOfStock);
  }, [cart, quantityRef]);

  useEffect(() => {
    // Open cart modal when quantity changes.
    if (cart?.totalQuantity !== quantityRef?.current && cart?.totalQuantity > 0) {
      // But only if it's not already open (quantity also changes when editing items in cart).
      if (!openCart) {
        setOpenCart(true);
      }
      // Always update the quantity reference
      quantityRef.current = cart?.totalQuantity;
    }
  }, [openCart, cart?.totalQuantity, quantityRef]);
  const handleCheckout = () => {
    window?.fbq("track", "InitiateCheckout");
    router?.push("/checkout");
    toggleCart();
  };
  let bundleDiscountInfo = null;
  let itemsWithDiscountAmount = null;
  if (cartItems?.collection_category?.meta_description) {
    const x = cartItems?.collection_category?.meta_description?.substr(
      1,
      cartItems?.collection_category?.meta_description?.length - 2
    );
    bundleDiscountInfo = JSON?.parse(x);
    itemsWithDiscountAmount = spreadDiscountToItems(
      bundleDiscountInfo?.price + bundleDiscountInfo?.discount,
      bundleDiscountInfo?.price,
      cartItems?.collection_products?.products
    );
    console.log("testing the function", itemsWithDiscountAmount);
  }
  return (
    <>
      <button onClick={() => toggleCart()}>
        <OpenCart quantity={cartItems?.cartQuantity || cart?.totalQuantity} />
      </button>
      <AnimatePresence>
        {openCart && (
          <div className="fixed inset-0 h-screen w-full z-30 bg-Stroke_Grey_Overlay flex justify-end">
            <div onClick={toggleCart} className="flex-1" />
            <motion.div
              initial={{ x: 999 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.2, type: "tween" }}
              exit={{ x: 999 }}
              className=" w-screen sm:w-[382px] pr-8 h-screen  z-40  sm:right-0 overflow-y-auto bg-white sm:px-3 pb-4 sm:pb-7"
            >
              <div className=" mt-3 ml-4 sm:ml-0">
                {/* Your cart heading and cross icon */}
                <div className="flex justify-between items-center border-b border-Stroke_Grey pb-3">
                  <div className="cursor-pointer ">
                    {breakpoint && (
                      <Image
                        width={16}
                        height={16}
                        src={BlackCross}
                        onClick={() => toggleCart()}
                        alt="Black Cross icon"
                      />
                    )}
                  </div>
                  <div>
                    <TypographyMontserrat
                      fontWeight={"bold"}
                      size={"xl"}
                      text={YOUR_CART_TEXT}
                      customStyle={" md:!text-2xl	"}
                    />
                  </div>
                  <div className="cursor-pointer ">
                    {!breakpoint && (
                      <Image
                        width={16}
                        height={16}
                        src={BlackCross}
                        onClick={() => toggleCart()}
                        alt="Black Cross icon"
                      />
                    )}
                  </div>
                </div>
                {/* Products list */}
                <div className="tamara-cart mt-6 sm:mt-3 grid gap-y-4 max-h-80 sm:max-h-96 overflow-scroll ">
                  {cartItems?.collection_products?.length
                    ? cartItems?.collection_products?.map((bundle, index) => (
                        <BundleCartCard
                          collection={bundle?.[index]?.category_details}
                          price={bundle?.[index]?.total_price}
                          exchangeRate={exchangeRate}
                          selectedCurrency={selectedCurrency}
                          collectionItems={bundle?.[index]?.products}
                          key={
                            bundle?.[index]?.category_details?.category_uuid +
                            index
                          }
                        />
                      ))
                    : null}
                  {cartItems?.collection_products?.length &&
                  cartItems?.non_collection_products?.products?.length > 0
                    ? cartItems?.non_collection_products?.products?.map(
                        (item, index) => (
                          <EcomCartCard
                            brandsData={brandsData}
                            item={item}
                            key={item?.id + index}
                            selectedCurrency={selectedCurrency}
                            exchangeRate={exchangeRate}
                          />
                        )
                      )
                    : null}
                  {!cartItems?.collection_products?.length
                    ? cart?.lines?.map((item, index) => (
                        <EcomCartCard
                          brandsData={brandsData}
                          item={item}
                          key={item?.id + index}
                          selectedCurrency={selectedCurrency}
                          exchangeRate={exchangeRate}
                        />
                      ))
                    : null}
                  {!cartItems?.collection_products &&
                    !cartItems?.non_collection_products?.products?.length &&
                    !cart?.lines?.length && (
                      <TypographyMontserrat
                        align={"center"}
                        fontWeight={"normal"}
                        size={"xl"}
                        text={NO_ITEMS}
                      />
                    )}
                </div>
                {/* Subtotal */}
                <div className="flex flex-col gap-y-1 mt-6 sm:mt-5 py-2   border-t border-Stroke_Grey ">
                  {cart?.cost?.subtotalAmount?.amount > 0 && (
                    <div className="flex justify-between ">
                      <TypographyMontserrat
                        fontWeight={"normal"}
                        size={"base"}
                        text={SUB_TOTAL_TEXT}
                      />
                      <TypographyMontserrat
                        fontWeight={"normal"}
                        size={"base"}
                        text={
                          selectedCurrency +
                          " " +
                            cart?.cost?.subtotalAmount?.amount
                          
                        }
                      />
                    </div>
                  )}
                  {cart?.cost && (
                    <div className="flex justify-between ">
                      <TypographyMontserrat
                        size={"base"}
                        text={"SHIPPING"}
                        customStyle=" uppercase h-fit "
                      />
                      <TypographyMontserrat
                        size={"base"}
                        text={"Calculated at checkout"}
                        customStyle="  h-fit "
                        fontWeight="light"
                      />
                    </div>
                  )}
                  {cart?.cost?.totalAmount?.amount > 0 && (
                    <>
                      <div className="flex justify-between ">
                        <TypographyMontserrat
                          fontWeight={"normal"}
                          size={"base"}
                          text={
                            selectedCurrency !== AED_TEXT
                              ? ESTIMATED_TOTAL_TEXT
                              : TOTAL_TEXT
                          }
                        />
                        <TypographyMontserrat
                          fontWeight={"normal"}
                          size={"base"}
                          text={
                            selectedCurrency +
                            " " +
                              cart?.cost?.totalAmount?.amount
                            
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                {/* Checkout and Continue Shopping */}

                {outOfStock && (
                  <TypographyMontserrat
                    size="sm"
                    color={"red"}
                    fontWeight="medium"
                    text="Sorry, one of the items is no longer available. Kindly remove it from your cart to proceed with your purchase smoothly."
                  />
                )}
                <div className="flex flex-col justify-center items-center gap-y-4 mt-6 sm:mt-9">
                  {cart?.totalQuantity > 0 && (
                    <button
                      disabled={outOfStock}
                      className={
                        "w-[180px] h-[48px] sm:w-[210px] disabled:bg-Button_Disabled  flex items-center justify-center gap-2 text-center bg-New_Primary_Default hover:bg-New_Primary_Hover active:bg-New_Primary_Pressed hover:border-New_Primary_Hover active:border-New_Primary_Pressed focus:outline-none  rounded-sm"
                      }
                      onClick={handleCheckout}
                    >
                      <span className=" text-sm sm:text-base font-bold text-Text_White   ">
                        {CHECKOUT_TEXT}
                      </span>
                    </button>
                  )}

                  <div className="cursor-pointer" onClick={toggleCart}>
                    <TypographyMontserrat
                      fontWeight={"bold"}
                      customStyle={"underline uppercase"}
                      size={"base"}
                      text={CONTINUE_SHOPPING_TEXT}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SlideCartDrawer;
