"use client";
import React from "react";
import { WhatsAppIcon } from "../../../public/Theme";
import Link from "next/link";
import {
  WHATSAPP_HUBSPOT,
  WHATSAPP_ICON_CLASS,
  WHATSAPP_IMAGE_CLASS,
  WHATSAPP_URL,
} from "./constants";
import Image from "next/image";
import { sendGTMEvent } from "@next/third-parties/google";

const Whatsapp = () => {
  return (
    <>
      <Link
        className={WHATSAPP_ICON_CLASS}
        href={WHATSAPP_URL + WHATSAPP_HUBSPOT}
        target="_blank"
        rel="noreferrer"
        alt="Message us"
        onClick={() =>
          sendGTMEvent({
            event: "NavigationInteractions",
            menu_name: "WhatsappButton",
            place: "floatingbuton",
          })
        }
      >
        <Image
          width={0}
          height={0}
          src={WhatsAppIcon}
          className={WHATSAPP_IMAGE_CLASS}
          alt="Message us"
          priority
        />
      </Link>
    </>
  );
};

export default Whatsapp;
