import React from "react";
import Link from "next/link";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { COLLECTION_TEXT, COLLECTION_IMAGE_ALT } from "./constant";
import { COLLECTIONS_LINK } from "../NewHeaderSection/constants";
import { sendGTMEvent } from "@next/third-parties/google";

const HeaderCollectionBanner = () => {
  return (
    <div className="flex max-w-[1440px] mx-auto">
      <div>
        <TypographyMontserrat
          size={"xl"}
          fontWeight={"light"}
          text={COLLECTION_TEXT}
        />

        <ul className="mt-3">
          {COLLECTIONS_LINK?.map(({ link, text }, index) => (
            <li key={index} className="my-3">
              <Link
                href={link}
                passHref
                onClick={() =>
                  sendGTMEvent({
                    event: "NavigationInteractions",
                    menu_name: text,
                    place: "topnavcollectionmenu",
                    
                  })
                }
              >
                <TypographyMontserrat
                  size={"sm"}
                  fontWeight={"medium"}
                  text={text}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <ul className="mt-3 ml-16 flex-1 grid grid-cols-5 justify-between">
        {COLLECTIONS_LINK.slice(0, -1)?.map(({ link, text, src }, index) => (
          <li key={index} className="my-3 mx-3">
            <Link
              href={link}
              passHref
              onClick={() =>
                sendGTMEvent({
                
                  event: "NavigationInteractions",
                  menu_name: text,
                  place: "topnavcollectionmenu",
                })
              }
            >
              <img src={src} alt={COLLECTION_IMAGE_ALT} />
              <TypographyMontserrat
                size={"base"}
                fontWeight={"medium"}
                text={text}
                customStyle={" mt-2 "}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderCollectionBanner;
