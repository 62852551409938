"use client";
import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

const AcceptCookieConsent = ({ setConsent, firstLoad, action }) => {
  const pathname = usePathname();
  const ref = useRef(pathname);
  useEffect(() => {
    console.log("pathname", pathname, ref.current, action);
    if (ref.current !== pathname && firstLoad?.value !== false) {
      // Do something before the page changes
      console.log("HHH");
      setConsent();
    }
    // Update the ref to store the current pathname
    ref.current = pathname;
  }, [pathname]);
  return null;
};

export default AcceptCookieConsent;
