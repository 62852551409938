import {
  ClipboardIcon,
  CrossInCircleRed,
  SuccessTickPink,
  TickInCircleGreen,
} from "../../../public/Theme";
export const TOAST_TIME = 5000;
export const contextClass = {
  success: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-auto",
  error: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-auto",
  info: "md:min-w-max md:pr-20 !right-0 !left-0 !mx-auto",
  warning: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-auto",
  default: "md:min-w-max md:pr-20  !right-0 !left-0 !mx-auto",
};

export const contextIcons = {
  success: TickInCircleGreen,
  error: CrossInCircleRed,
  info: ClipboardIcon,
  warning: SuccessTickPink,
  default: SuccessTickPink,
};
