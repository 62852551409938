import LoadingDots from "../../Atoms/LoadingDots";
import { useRouter } from "next/navigation";
import { BlackBin } from "../../../public/Theme";
import clsx from "clsx";
import { removeMultipleItems } from "../../../lib/cart/actions";
import { useTransition } from "react";
import { updateLocalStorage } from "@/lib/utils";

export default function DeleteMultipleItemsButton({ items,bundleId }) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const variantIds = items?.map((item) => String(item?.variant?.id));
 
  return (
    <button
      aria-label="Remove cart item"
      onClick={() => {
        startTransition(async () => {
          try {
            await removeMultipleItems(variantIds,bundleId);
            updateLocalStorage(variantIds);
          } catch (e) {
            if (e) {
              // Trigger the error boundary in the root error.js
              throw new Error(e.toString());
            }
          }
          router.refresh();
        });
      }}
      disabled={isPending}
      className={clsx(
        "ease flex items-center justify-center transition-all duration-200",
        {
          "cursor-not-allowed px-0": isPending,
        }
      )}
    >
      {isPending ? (
        <LoadingDots className="bg-black " />
      ) : (
        <img src={BlackBin} alt="delete-bin-icon" />
      )}
    </button>
  );
}
