import React from "react";
import { INPUT_CONTAINER } from "./constants";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputLabelLight } from "../../Typography/InputLabel";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";

const NewInputField = ({
  errors,
  label,
  type,
  placeholder,
  field,
  register = () => {},
  height = "",
  style = "",
  name,
  disabled,
  defaultValue,
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  const inputClassName = errors?.[field]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const errorMessage = name
    ? errors?.[name]?.message
    : errors?.[field]?.message;

  return (
    <div className={INPUT_CONTAINER}>
      {label && <InputLabelLight label={label} />}
      <div>
        <input
          className={inputClassName + height + style}
          type={type}
          placeholder={placeholder}
          {...register(field)}
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <InputErrorMessage errorMessage={errorMessage} />
      </div>
    </div>
  );
};

export default NewInputField;
