"use client";
import React from "react";
import Link from "next/link";
import { sendGTMEvent } from "@next/third-parties/google";

const FooterLink = ({ text, href, redirect }) => {
  let gtmObj = { name: text };
  if (redirect) gtmObj.redirectedToWearThatSite = redirect;
  return (
    <li>
      <Link
        href={href || ""}
        className=" font-medium text-[13px] leading-4 md:text-sm  "
        onClick={() =>
          sendGTMEvent({
            event: "NavigationInteractions",
            menu_name: gtmObj, 
            place: "footer"
          })
        }
      >
        {text}
      </Link>
    </li>
  );
};

export default FooterLink;
