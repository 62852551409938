"use client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendGTMEvent } from "@next/third-parties/google";
import {
  EMAIL_FIELD_NAME,
  EMAIL_TEXT,
  SUBSCRIBE_TEXT,
  THANK_YOU_TEXT,
} from "./constants";

import { DEFAULT_VALUES, NEWSLETTER_SCHEMA_EN } from "./FormSchema";
import {
  BUTTON_TYPE,
  NEWSLETTER_ENDPOINT,
  SUBMIT_TYPE,
  SURVEY_ENDPOINT,
  TAKE_STYLE_QUIZ,
} from "../../../public/Constants/EnumConstants";
import {
  NewInputField,
  NewInputFieldWithButton,
} from "../../Atoms/Inputs/NewInputField";
import { UNORDERED_LIST_CLASS } from "./constants";
import NewPrimaryButton from "../../Atoms/Buttons/NewPrimaryButton";
import {
  NEWSLETTER_FOOTER_CLASS,
  NEWS_LETTER_FOOTER_TEXT,
  NEWS_LETTER_TERMS_CTA,
  NEWS_LETTER_TERMS_CTA_CLASS,
  NEWS_LETTER_TERMS_DOT,
  TERMS_AND_CONDITIONS_LINK,
} from "../NewFooterSection/constants";

const NewsletterForm2 = ({ buttonAgainstField }) => {
  const [formSubmitted, setFormSubmitted] = useState({
    processing: false,
    submitted: false,
    userEmail: null,
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(NEWSLETTER_SCHEMA_EN),
  });
  const NewLetterApiCall = () => {
    try {
      const formValues = getValues();
      const { EmailSubscribe } = formValues;

      const completeData = {
        email: EmailSubscribe,
      };
      const url = `${process.env.BACKEND_URL}${NEWSLETTER_ENDPOINT}`;
      const response = fetch(url, {
        body: JSON.stringify(completeData),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setFormSubmitted((prev) => ({
            ...prev,
            processing: false,
            submitted: true,
          }));
          reset();
          if (response.status != 200) {
            window.newrelic?.noticeError(
              `error in newsletter api ${response.status}`,
              { attribute1: { data: JSON.stringify(completeData) } }
            );
          }
        })
        .catch((error) => {
          window.newrelic?.noticeError(`error in newsletter api ${error}`);
          console.log("error", error);
        });
    } catch (error) {
      window.newrelic?.noticeError(error);
      console.log("error", error);
      // push to error page
    }
  };
  const onsubmit = (e) => {
    setFormSubmitted((prev) => ({
      ...prev,
      processing: true,
      userEmail: e?.EmailSubscribe,
    }));
    NewLetterApiCall();
    sendGTMEvent({
      event: "FormInteractions",
      interaction_item: "Newsletter",
      interaction_item: `{userEmail:${e?.EmailSubscribe}}`,
    });
  };

  const InputFieldComponent = () => {
    return buttonAgainstField ? (
      <>
        <NewInputFieldWithButton
          buttonText={SUBSCRIBE_TEXT}
          errors={errors}
          field={EMAIL_FIELD_NAME}
          name={EMAIL_FIELD_NAME}
          placeholder={EMAIL_TEXT}
          register={register}
          processing={formSubmitted?.processing}
          disableButton={formSubmitted?.processing}
        />
      </>
    ) : (
      <>
        <NewInputField
          errors={errors}
          field={EMAIL_FIELD_NAME}
          // label={getNewsletterTextTwo(lang)}
          name={EMAIL_FIELD_NAME}
          placeholder={EMAIL_TEXT}
          register={register}
        />

        <NewPrimaryButton
          text={SUBSCRIBE_TEXT}
          type={SUBMIT_TYPE}
          processing={formSubmitted?.processing}
          className="w-[100%] mt-1"
        />
      </>
    );
  };

  return !formSubmitted?.submitted ? (
    <form onSubmit={handleSubmit(onsubmit)}>
      <>
        <InputFieldComponent />
        <h2 className={NEWSLETTER_FOOTER_CLASS}>
          {NEWS_LETTER_FOOTER_TEXT}
          <a
            className={NEWS_LETTER_TERMS_CTA_CLASS}
            href={TERMS_AND_CONDITIONS_LINK}
          >
            {NEWS_LETTER_TERMS_CTA}
          </a>
          {NEWS_LETTER_TERMS_DOT}
        </h2>
      </>
    </form>
  ) : (
    <div>
      <p className={UNORDERED_LIST_CLASS}>{THANK_YOU_TEXT}</p>
      <br />
      <NewPrimaryButton
        type={BUTTON_TYPE}
        target={"_blank"}
        text={TAKE_STYLE_QUIZ}
        href={SURVEY_ENDPOINT + `?userEmail=${formSubmitted?.userEmail}`}
      />
      <br />
    </div>
  );
};

export default NewsletterForm2;
