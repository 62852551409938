const YoutubeIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8096 34H11.1904C7.22533 34 4 30.1878 4 25.5011V14.4989C4 9.81223 7.22533 6 11.1904 6H29.8096C33.7747 6 37 9.81223 37 14.4989V25.5011C37 30.1878 33.7747 34 29.8096 34ZM11.1904 7.362C7.86025 7.362 5.15232 10.564 5.15232 14.4989V25.5011C5.15232 29.4373 7.86141 32.638 11.1904 32.638H29.8096C33.1397 32.638 35.8477 29.4359 35.8477 25.5011V14.4989C35.8477 10.5627 33.1386 7.362 29.8096 7.362H11.1904Z"
      fill="#5C5C5C"
      stroke="#5C5C5C"
      strokeWidth="1.3"
    />
    <path
      d="M25.3364 20.5359L16.6272 25.4459C16.3956 25.5766 16.1271 25.3764 16.1271 25.0727V14.9462C16.1271 14.6384 16.4025 14.4382 16.6352 14.5771L25.3444 19.7936C25.6026 19.9502 25.5991 20.3888 25.3364 20.5359Z"
      fill="#5C5C5C"
    />
    <path
      d="M16.4762 26.1674C16.3045 26.1674 16.134 26.1102 15.983 25.9972C15.7122 25.7956 15.5509 25.4497 15.5509 25.071V14.9445C15.5509 14.5604 15.7157 14.2118 15.9922 14.0116C16.2688 13.8127 16.6064 13.795 16.8957 13.9694L25.6048 19.1858C25.9217 19.3765 26.1165 19.7551 26.1119 20.176C26.1073 20.5955 25.9056 20.9687 25.5853 21.1498L16.8761 26.0598C16.7482 26.132 16.6122 26.1674 16.4762 26.1674ZM16.7032 15.3831V24.6488L24.6738 20.1569L16.7032 15.3831Z"
      fill="#5C5C5C"
    />
  </svg>
);
export default YoutubeIcon;
