import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/Transition.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Atoms/AcceptCookieConsent/AcceptCookieConsent.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Atoms/CustomToastContainer/CustomToastContainer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Atoms/FooterLinks/FooterLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Atoms/WhatsApp/WhatsApp.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/CurrencySelectorDropdown/CurrencySelectorDropdown.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/FooterSocialMediaLink/FooterSocialMediaLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/NewFooterSection/ClientSideComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/NewHeaderSection/NewHeaderSection.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/NewsletterForm2/NewsletterForm2.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/PageTopBanner/PageTopBanner.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/ShopHeader/HeaderSearchBar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/ShopHeader/LinksComponent.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Molecules/SlideCartDrawer/SlideCartDrawer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick-theme.css")