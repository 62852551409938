import { NewDropdownArrowIcon } from "@/components/Atoms/SVGs";
import { ECOM_TERMS_AND_CONDITIONS_LINK } from "@/public/Constants/EnumConstants";
import Link from "next/link";

export const BANNER_TEXT = (
  <>
    International Shipping | Free delivery & returns over 500 AED | Express
    delivery in UAE.{"   "}
    <Link
      target="_blank"
      className="text-sm sm:text-base font-bold underline underline-offset-2 inline-flex "
      prefetch
      href={ECOM_TERMS_AND_CONDITIONS_LINK}
    >
      T&C
      <span className="-rotate-90 ml-2 sm:ml-3">
        <NewDropdownArrowIcon width={"12"} height={"12"} />
      </span>
    </Link>
  </>
);
