import { getCartForClient, setBundleIdsInCookies } from "./serverFunction";

const endpoint = process?.env?.PAYMENT_URL;
function findProductByVariantId(products, variantId) {
  return products?.find((product) =>
    product?.merchandise?.product?.variants?.find(
      (variant) => variant?.id === variantId
    )
  );
}
export async function verifyCollectionProducts(
  products,
  cartId,
  bundleIds,
  headers = {}
) {
  if (products?.length < 0) return;
  // console.log("=======products======", products);
  if (!bundleIds) return;
  const bundlesData = JSON.parse(localStorage?.getItem("bundles"));

  // console.log("=======bundlesData======", bundlesData);
  // Iterate through the products list to update bundlesData
  // const updatedBundlesData = bundlesData?.map((bundle) =>
  //   bundle?.map((bundleItem) => {
  //     const product = products?.find((product) =>
  //       product?.merchandise?.product?.variants?.find(
  //         (variant) => variant?.id === product?.merchandise?.id
  //       )
  //     );
  //     return {
  //       id: bundleItem?.parentId,
  //       category_id: bundleItem?.bundleId,
  //       line_id: product ? product?.id : null,
  //       variant: {
  //         id: bundleItem?.variantId,
  //         quantity: JSON.stringify(product?.quantity),
  //       },
  //     };
  //   })
  // );
  const updatedBundlesData = [];
  for (let i = 0; i < bundlesData?.length; i++) {
    const bundle = bundlesData[i];
    const updatedBundle = [];
    for (let j = 0; j < bundle?.length; j++) {
      const bundleItem = bundle[j];
      const product = findProductByVariantId(products, bundleItem?.variantId);

      // console.log("=======productfound======", product);
      updatedBundle.push({
        id: bundleItem?.parentId,
        category_id: bundleItem?.bundleId,
        line_id: product ? product?.id : null,
        variant: {
          id: bundleItem?.variantId,
          quantity: JSON.stringify(product?.quantity),
        },
      });
    }
    updatedBundlesData.push(updatedBundle);
  }

  // Save the updated bundlesData back to localStorage

  const filteredProducts = products
    ?.map((product) => {
      // Find the matching variant based on selected color and size
      const matchingVariant = product?.merchandise?.product?.variants?.find(
        (variant) => variant?.id === product?.merchandise?.id
      );

      const variantId = matchingVariant?.id;
      const parentId = matchingVariant?.parentId;

      // Step 2: Check if the variantId or parentId is in bundlesData
      const isInBundles = bundlesData?.some((bundle) =>
        bundle?.some(
          (item) => item?.variantId === variantId && item?.parentId === parentId
        )
      );

      // If not found in bundles, return product info
      if (!isInBundles) {
        return {
          id: parentId,
          line_id: product ? product?.id : null,
          variant: {
            id: variantId,
            quantity: JSON.stringify(product?.quantity),
          },
          category_id: null,
        };
      }

      // If found in bundles, return null (or skip)
      return null;
    })
    .filter(Boolean); // Filter out null values

  const firstBundle =
    filteredProducts?.length === 0 && updatedBundlesData?.length === 1;

  const data = JSON.stringify({
    products: filteredProducts,
    bundles: { ...updatedBundlesData },
    cart_id: cartId,
  });
  const verifyCollectionEndpoint = endpoint + "/products/verify-collection";
  try {
    const response = await fetch(verifyCollectionEndpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
      body: data,
      mode: "cors",
    });
    const result = await response?.json();
    if (firstBundle) {
      const cart = await getCartForClient(cartId);
      return { ...result, cart };
    }
    return result;
  } catch (e) {
    console.log(e);
  }
}

export const removeItemsFromCart = async (cartId, itemIds, headers = {}) => {
  const body = JSON.stringify({
    cart_id: String(cartId),
    product_ids: itemIds,
  });
  const removeItemFromCartEndpoint = endpoint + "/products/cart-items";
  try {
    const response = await fetch(removeItemFromCartEndpoint, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
      body: body,
      mode: "cors",
    });
    const result = await response?.json();
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getItemIds = async (cartId, variantIds, headers = {}) => {
  const body = JSON.stringify({
    cart_id: String(cartId),
    variant_ids: variantIds,
  });
  const getItemIdsFromCartEndpoint = endpoint + "/products/cart-items";
  try {
    const response = await fetch(getItemIdsFromCartEndpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
      body: body,
      mode: "cors",
    });
    const result = await response?.json();
    return result?.data;
  } catch (e) {
    console.log(e);
  }
};
