export const LOGO_CLASS = "relative mx-4 w-24 sm:w-28 h-4 sm:h-5";
export const NAVBAR_CLASS =
  " bg-white dark:bg-White border-gray-200 py-3 flex items-center max-w-full relative";
export const LOGO_IMAGE_CLASS = "  ";
export const MENU_IMAGE_CLASS = "h-full w-full";
export const LINKS_AND_BUTTONS_CONTAINER =
  "flex justify-between items-center  max-w-full";
export const LINKS_CONTAINER =
  "hidden justify-between items-center w-full xl:flex lg:w-auto mx-auto ";
export const HEADER_UNORDERED_LIST_CLASS =
  "flex flex-col mt-4 font-medium lg:flex-row  lg:mt-0 px-14 ";
export const HEADER_LINK_CLASS =
  " block  py-2 pr-4 pl-3 text-center text-Text_Gray lg:mx-5 lg:p-0  text-sm text-Montserrat font-semibold hover:font-bold focus:font-bold ";
export const MENU_ICON_CLASS =
  "inline-flex items-center  text-sm  rounded-lg xl:hidden focus:outline-none focus:ring-2 ";
export const DASHBOARD_DRAWER_CONTAINER =
  " fixed z-40 h-screen top-0 right-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";
export const DASHBOARD_DRAWER_CONTAINER_AR =
  " fixed z-40 h-screen top-0  py-7 px-4 overflow-y-auto  bg-white w-screen sm:w-max ";
