"use client";
import Link from "next/link";
import {
  POLICY_TERM_CLASS,
  PRIVACY_POLICY_LINK,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS_LINK,
  TERMS_OF_USE,
} from "./constants";
import { sendGTMEvent } from "@next/third-parties/google";

export const PrivacyPolicyAndTermsOfUse = () => (
  <>
    <Link
      className={POLICY_TERM_CLASS}
      href={PRIVACY_POLICY_LINK}
      onClick={() =>
        sendGTMEvent({ event: "NavigationInteractions", menu_name: PRIVACY_POLICY , place:"footer"})
      }
    >
      {PRIVACY_POLICY}
    </Link>
    <Link
      className={POLICY_TERM_CLASS}
      href={TERMS_AND_CONDITIONS_LINK}
      onClick={() =>
        sendGTMEvent({ event: "NavigationInteractions", menu_name: TERMS_OF_USE, place:"footer" })
      }
    >
      {TERMS_OF_USE}
    </Link>
  </>
);
