const TikTokIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1873 7.60225C33.0002 7.48218 32.8132 7.48218 32.6261 7.54221C31.0674 8.20263 29.4463 7.72233 28.511 6.52158C28.0746 5.98124 27.8252 5.26079 27.8252 4.60038C27.8252 4.42026 27.7628 4.30019 27.6381 4.18011C27.5134 4.06004 27.3887 4 27.2017 4H20.8419C20.4678 4 20.2184 4.24015 20.2184 4.60038V25.9137C20.2184 25.9737 20.2184 25.9737 20.2184 26.0338C20.0937 27.1144 19.2208 27.8949 18.0985 27.8949C16.9139 27.8949 15.9163 26.9343 15.9163 25.7936C15.9163 24.7129 16.7268 23.8724 17.8491 23.7523C18.1609 23.6923 18.4103 23.4522 18.4103 23.152V16.1876C18.4103 15.8274 18.1609 15.5872 17.7868 15.5872C17.7868 15.5872 17.7244 15.5872 17.6621 15.5872C11.9259 15.8274 7.49902 20.2702 7.49902 25.7936C7.49902 31.4371 12.2376 36 18.0985 36C23.5854 36 28.1993 31.9174 28.6357 26.6942C28.6981 26.6341 28.6981 26.5141 28.6981 26.454V14.8668C30.0698 15.167 31.5038 15.2871 33.0002 15.0469C33.312 14.9869 33.499 14.7467 33.499 14.4465V8.08255C33.499 7.90244 33.3743 7.72233 33.1873 7.60225ZM32.252 13.9662C30.818 14.0863 29.5086 13.9662 28.1993 13.546C28.0122 13.4859 27.8252 13.546 27.6381 13.606C27.4511 13.7261 27.3887 13.9062 27.3887 14.0863V26.2139C27.3887 26.2739 27.3887 26.334 27.3264 26.394C27.0146 31.0769 22.8995 34.7992 18.0362 34.7992C12.8611 34.7992 8.68368 30.7767 8.68368 25.7936C8.68368 21.1107 12.3 17.2683 17.1009 16.848V22.6717C15.6669 23.0919 14.6069 24.3527 14.6069 25.7936C14.6069 27.5947 16.1657 29.0957 18.0362 29.0957C19.7196 29.0957 21.1537 27.8949 21.4031 26.334C21.4654 26.2739 21.4654 26.1538 21.4654 26.0938V5.20075H26.6405C26.7652 5.98124 27.077 6.64165 27.5134 7.24203C28.5734 8.62289 30.4439 9.34334 32.252 8.92308V13.9662Z"
        fill="#5C5C5C"
        stroke="#5C5C5C"
        strokeWidth="1.27273"
      />
    </svg>
  );
};
export default TikTokIcon;
