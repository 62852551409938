import { useEffect, useState } from "react";
import { verifyCollectionProducts } from "./backendCalls";
import {
  deleteBundleIdsInCookies,
  setBundleIdsInCookies,
} from "./serverFunction";

const useCartItems = (cart, bundleIds) => {
  const [cartItems, setCartItems] = useState({
    collection_category: null,
    collection_products: null,
    non_collection_products: cart?.lines,
    total_price: null,
    total_price_with_discount: null,
    cartQuantity: cart?.totalQuantity,
  });

  const priceDiff = cart?.cost - cartItems?.total_price;

  useEffect(() => {
    const getCustomizedCartData = async () => {
      if (cart?.lines) {
        if (typeof window !== undefined) {
          const bundlesData = JSON.parse(localStorage?.getItem("bundles"));
          setBundleIdsInCookies(bundlesData);

          const priceDiff =
            cart?.cost?.totalAmount?.amount ||
            0 - cartItems?.total_price_with_discount ||
            0;

          if (cart?.lines && priceDiff !== 0) {
            const apiCartData = await verifyCollectionProducts(
              cart?.lines,
              cart?.id,
              bundleIds
            );
            const totalProducts =
              apiCartData?.collection_products?.length === 0
                ? cart?.totalQuantity
                : (apiCartData?.non_collection_products?.products?.length ||
                    0) + (apiCartData?.collection_products?.length || 0);
            setCartItems({
              ...cartItems,
              collection_products: apiCartData?.collection_products,
              collection_category: apiCartData?.collection_category,
              non_collection_products: apiCartData?.non_collection_products,
              total_price: apiCartData?.total_price,
              total_price_with_discount: cart?.cost?.totalAmount?.amount,
              cartQuantity: totalProducts,
              cost: apiCartData?.cart?.cost || null,
            });
          }
        }
      } else {
        deleteBundleIdsInCookies();
        setCartItems({
          collection_category: null,
          collection_products: null,
          non_collection_products: null,
          total_price: null,
          total_price_with_discount: null,
          cartQuantity: null,
        });
      }
    };
    getCustomizedCartData();
  }, [cart?.lines]);

  return { cartItems };
};

export default useCartItems;
