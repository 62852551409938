"use client";
import Link from "next/link";
import dynamic from "next/dynamic";
import classNames from "classnames";
import React, { useState } from "react";

import {
  CloseIcon,
  WTLogoEnglish,
  BlackSimpleArrow,
} from "../../../public/Theme";

import {
  CLOSE_ICON_ALT,
  BACK_ICON_ALT,
  DRAWER_NAVIGATION,
  LAZY,
  LOGO_ALT_TEXT,
  BACK_TEXT,
} from "./constants";
import {
  CLOSE_BUTTON_CLASS,
  LOGO_IMAGE_CLASS,
  DRAWER_HEADER_CLASS,
  BACK_BUTTON_CLASS,
  BACK_BUTTON_ICON_CLASS,
} from "./styles";
import { BUTTON } from "../../../public/Constants/EnumConstants";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import Image from "next/image";

const DrawerHeader = ({
  homeUrl,
  handleOnClick,
  showCollections,
  setShowCollections,
}) => {
  const closeButtonClass = classNames(CLOSE_BUTTON_CLASS, "right-2.5");

  return (
    <div className={DRAWER_HEADER_CLASS}>
      {showCollections ? (
        <button
          type={BUTTON}
          data-drawer-dismiss={DRAWER_NAVIGATION}
          aria-controls={DRAWER_NAVIGATION}
          onClick={() => setShowCollections(false)}
          className={BACK_BUTTON_CLASS}
        >
          <Image
            src={BlackSimpleArrow}
            alt={BACK_ICON_ALT}
            className={BACK_BUTTON_ICON_CLASS}
            width={12}
            height={12}
          />
          <TypographyMontserrat
            fontWeight={"bold"}
            size={"13px"}
            text={BACK_TEXT}
          />
        </button>
      ) : (
        <Link href={homeUrl} className="relative w-24 sm:w-28 h-4 sm:h-5">
          <Image
            src={WTLogoEnglish}
            className={LOGO_IMAGE_CLASS}
            alt={LOGO_ALT_TEXT}
            fill
            sizes="(max-width:768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        </Link>
      )}
      <button
        type={BUTTON}
        data-drawer-dismiss={DRAWER_NAVIGATION}
        aria-controls={DRAWER_NAVIGATION}
        onClick={handleOnClick}
        className={closeButtonClass}
      >
        <Image width={24} height={24} src={CloseIcon} alt={CLOSE_ICON_ALT} />
      </button>
    </div>
  );
};

export default DrawerHeader;
