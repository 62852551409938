import { FRONTEND_ENDPOINTS } from "@/public/Constants/endpoints";
import {
  JessieCollection,
  OldMoneyEditCollection,
  SerenaBySeaCollection,
  SerenaCollection,
} from "@/public/Theme";

export const COLLECTIONS_LINK = [
  {
    link: FRONTEND_ENDPOINTS?.OLD_MONEY_EDIT_LINK,
    text: "Old Money Edit",
    src: OldMoneyEditCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.SERENA_BY_THE_SEA_PRODUCTS_LINK,
    text: "Serena By The Sea",
    src: SerenaBySeaCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.SERENA_PRODUCTS_LINK,
    text: "Serena Collection",
    src: SerenaCollection,
  },
  {
    link: FRONTEND_ENDPOINTS?.JESSIE_PRODUCTS_LINK,
    text: "Wear That x Jessie",
    src: JessieCollection,
  },
];
