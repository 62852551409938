const FacebookIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6452 36.9663H23.9452V36.6663V20.8372H28.2188H28.4927L28.5176 20.5644L29.1321 13.8149L29.1618 13.4877H28.8333H23.9452V11.3868C23.9452 11.1133 23.9534 10.9259 23.973 10.7948C23.9931 10.6604 24.0197 10.6259 24.0234 10.6217C24.0234 10.6216 24.0234 10.6216 24.0235 10.6216C24.0237 10.6212 24.0248 10.6198 24.0311 10.6169C24.0404 10.6127 24.0601 10.6058 24.0984 10.5999C24.1824 10.587 24.297 10.5862 24.4835 10.5862H28.7707H29.0707V10.2862V3.33301V3.03301H28.7707H23.4798C20.8335 3.03301 18.7851 3.61474 17.3959 4.82101C15.9988 6.03414 15.3184 7.83473 15.3184 10.158V13.489H12.1666H11.8666V13.789V20.606V20.906H12.1666H15.3184V36.6663V36.9663H15.6184H23.6452ZM22.2537 18.8897H21.9537V19.1897V35.0175H17.3072V19.2585V18.9585H17.0072H13.8554V15.4392H17.0072H17.3072V15.1392V10.1594C17.3072 8.34585 17.8044 7.07557 18.7809 6.2492C19.7681 5.41374 21.3012 4.98317 23.4784 4.98317H27.0792V8.63869H24.4821C23.4403 8.63869 22.7724 8.9557 22.3863 9.51627C22.018 10.051 21.9537 10.7459 21.9537 11.3881V15.1379V15.4379H22.2537H26.9865L26.6703 18.8897H22.2537Z"
      fill="#5C5C5C"
      stroke="#5C5C5C"
      strokeWidth="0.6"
    />
  </svg>
);
export default FacebookIcon;
