import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const FlyoutLink = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);
  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className=" w-fit h-fit"
    >
      <span className="cursor-pointer">{children}</span>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, zIndex: 10, top: 60 }}
            animate={{ opacity: 1, zIndex: 10, top: 32 }}
            exit={{ opacity: 0, zIndex: 10, top: 60 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
            className="absolute w-screen top-8 -left-9 z-10"
          >
            <div className=" w-full h-3 bottom-0 right-0"></div>
            <div className=" w-full px-20 py-7 bg-White_Smoke">
              <FlyoutContent />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FlyoutLink;
