import * as yup from "yup";

export const NEWSLETTER_SCHEMA_EN = yup.object({
  EmailSubscribe: yup
    .string()
    .email("Please Enter a Valid Email")
    .required("Please Enter Email Address"),
});


export const DEFAULT_VALUES = {
  EmailSubscribe: "",
};
