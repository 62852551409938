import TypographyMontserrat, {
  Heading,
} from "@/components/Atoms/TypographyMontserrat";
import React from "react";
import { SearchBarShoppingProductCard } from "../ShoppingProductCard";
import WearthatLoader from "@/components/Atoms/WearthatLoader";
import NewPrimaryButton from "@/components/Atoms/Buttons/NewPrimaryButton";
import { sendGTMEvent } from "@next/third-parties/google";

const SearchBarSearchedProducts = ({
  products,
  searching,
  searchValue,
  setShowSearchBar,
}) => {
  return (
    <div className="relative">
      <Heading
        customStyle={"!text-base lg:!text-xl"}
        fontWeight="light"
        text={"Products"}
      />
      {searching ? (
        <div className="mt-2">Searching...</div>
      ) : products?.length > 0 ? (
        <>
          <div className=" grid grid-cols-2 min-[1420px]:grid-cols-4 justify gap-y-5 gap-x-3 lg:gap-x-5 mt-2">
            {products?.map((product, index) => {
              return (
                <SearchBarShoppingProductCard
                  key={index}
                  link={`${product?.path || product?.handle}`}
                  name={product?.name || product?.title}
                  images={[product?.images?.[0]]}
                  price={
                    product?.prices?.price?.value ||
                    product?.priceRange?.maxVariantPrice?.amount
                  }
                  currencyCode={
                    product?.priceRange?.maxVariantPrice?.currencyCode
                  }
                  onClick={() => {
                    sendGTMEvent({
                      event: "SearchBarInteraction",
                      interaction_type: "click",
                      interaction_item: `SearchedProducts:${product?.name}`,
                    });
                    setShowSearchBar(false);
                  }}
                />
              );
            })}
          </div>
          <div className="mt-9 lg:mt-4 pb-9 lg:pb-5">
            <NewPrimaryButton
              className={"!mx-auto lg:!mx-0 "}
              text={"View All Results"}
              href={`/search?q=${searchValue}`}
            />
          </div>
        </>
      ) : searchValue === "" ? (
        <TypographyMontserrat
          customStyle={"!text-[18px] lg:!text-2xl mt-2 lg:mt-[22px]"}
          fontWeight="normal"
          text={"Start Typing For Search Results"}
        />
      ) : (
        <TypographyMontserrat
          customStyle={"!text-[18px] lg:!text-2xl mt-2 lg:mt-[22px]"}
          fontWeight="normal"
          text={"No Products Found for your search"}
        />
      )}
    </div>
  );
};

export default SearchBarSearchedProducts;
