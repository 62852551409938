import TypographyMontserrat, {
  Heading,
} from "@/components/Atoms/TypographyMontserrat";
import React from "react";
import { COLLECTIONS_LINK } from "./constants";
import Link from "next/link";
import Image from "next/image";

const SearchBarCollectionsSection = ({ setShowSearchBar }) => {
  return (
    <div>
      <Heading
        customStyle={"!text-base lg:!text-xl mb-2"}
        fontWeight="light"
        text={"Collections"}
      />
      <ul className="searchbar-collections min-[930px]:max-w-[380px] flex gap-y-5 gap-x-[14px] min-[930px]:gap-x-5 flex-nowrap min-[930px]:flex-wrap overflow-scroll min-[930px]:overflow-auto">
        {COLLECTIONS_LINK?.map(({ link, text, src }, index) => (
          <li key={index} className="">
            <Link
              onClick={() => setShowSearchBar(false)}
              className="flex flex-col min-w-[140px]"
              href={link}
              passHref
            >
              <Image
                width={0}
                height={0}
                src={src}
                sizes="100vw"
                alt={"Collection Image"}
                className="w-[160px] h-[100px] object-cover"
              />
              <TypographyMontserrat
                size={"base"}
                text={text}
                customStyle={
                  "text-nowrap text-xs lg:text-[13px] !font-medium lg:!font-normal mt-1 lg:mt-2 "
                }
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchBarCollectionsSection;
