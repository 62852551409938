"use client";
import dynamic from "next/dynamic";
import React, { useState, useCallback } from "react";

import DrawerHeader from "./DrawerHeader";

import { DRAWER_NAVIGATION, COLLECTIONS_TEXT } from "./constants";
import {
  DRAWER_CLOSE_TRANSITION,
  DRAWER_CONTAINER,
  DRAWER_INNER_CONTAINER,
  DRAWER_OPEN_TRANSITION,
  DRAWER_PARENT_CONTAINER,
  DRAWER_OUTER_CONTAINER,
  MARGING_3,
  SPACE_Y_2,
} from "./styles";
import { MINUS_ONE } from "../../../public/Constants/EnumConstants";

const HeaderLinkWithIcon = dynamic(() =>
  import("../../Atoms/Typography/HeaderLinkWithIcon")
);
const NavBarCollectionsSection = dynamic(() =>
  import("../NavBarCollectionsSection")
);

const NavBarDrawer = ({
  homeUrl,
  headerLinks,
  collectionLinks,
  drawerOpen,
  setDrawerOpen,
  Currency,
}) => {
  const [showCollections, setShowCollections] = useState(false);

  const handleOnClick = useCallback(() => {
    setDrawerOpen((prev) => !prev);
    setShowCollections(false);
  }, []);

  const handleLinkClick = useCallback((text) => {
    return text === COLLECTIONS_TEXT
      ? () => setShowCollections((prev) => !prev)
      : handleOnClick;
  }, []);

  return (
    <div
      className={
        DRAWER_PARENT_CONTAINER +
        (drawerOpen ? DRAWER_OPEN_TRANSITION : DRAWER_CLOSE_TRANSITION)
      }
    >
      <div
        id={DRAWER_NAVIGATION}
        className={DRAWER_CONTAINER}
        tabIndex={MINUS_ONE}
      >
        <DrawerHeader
          homeUrl={homeUrl}
          handleOnClick={handleOnClick}
          showCollections={showCollections}
          setShowCollections={setShowCollections}
        />
        {showCollections ? (
          <NavBarCollectionsSection
            collectionLinks={collectionLinks}
            onClick={handleOnClick}
          />
        ) : (
          <div className={DRAWER_INNER_CONTAINER}>
            <ul className={SPACE_Y_2}>
              {headerLinks?.map((item, index) => (
                <li key={index}>
                  <HeaderLinkWithIcon
                    text={item?.text}
                    link={item?.link}
                    src={item?.src}
                    onClick={handleLinkClick(item?.text)}
                    endEndorment={true}
                    margin={MARGING_3}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div onClick={handleOnClick} className={DRAWER_OUTER_CONTAINER}></div>
    </div>
  );
};

export default NavBarDrawer;
